
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'labelCalcList'
})
export default class extends Vue {
  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData = []

  created () {
    this.getData()
  }

  activated () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.preBidCalc.selectCalculateFrontByPage, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({ name: 'preBidCalcAdd' })
  }

  onDetail (frontId: string) {
    this.$router.push({ name: 'preBidCalcDetail', params: { id: frontId } })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
